<template>
    <ListItem class="no-padding" @click="$emit('click', trip)">
        <div
            class="l-stack l-spread l-full-width l-gap-4 timeline"
            :style="'--color-trace: ' + traceColor"
        >
            <div class="l-stack l-gap-1">
                <div>
                    <div class="timline-bubble" />
                    <div class="l-inline l-start l-spread l-gap-2">
                        <div class="l-inline l-center-v l-gap-1">
                            <CalendarIcon height="18" width="18" />
                            <div>
                                {{
                                    formatDate(trip.first_measurement.timestamp)
                                }}
                            </div>
                            <div style="font-weight: bold">
                                {{
                                    formatTime(trip.first_measurement.timestamp)
                                }}
                            </div>
                        </div>
                        <span>
                            {{
                                formatTripDuration(
                                    trip.first_measurement.timestamp,
                                    trip.last_measurement.timestamp
                                )
                            }}
                        </span>
                    </div>
                </div>
                <div v-if="trip.driver_id" class="l-inline l-center-v l-gap-1">
                    <div>
                        <PersonIcon height="18" width="18" />
                    </div>
                    <p class="t-small">
                        {{ trip.driver_name }} ({{ trip.driver_id }})
                    </p>
                </div>
                <div class="l-inline l-start l-spread l-gap-2">
                    <div class="l-inline l-center-v l-gap-1">
                        <div>
                            <PinLocationIcon height="18" width="18" />
                        </div>
                        <p>
                            <span class="t-small">{{
                                trip.start_address
                            }}</span>
                        </p>
                    </div>
                    <span v-if="trip.trip_distance">
                        {{ formatDistance(trip.trip_distance) }}
                    </span>
                </div>
                <div
                    v-if="trip.trailers && trip.trailers.length"
                    class="l-inline l-center-v l-gap-1"
                >
                    <div>
                        <TrailerIcon height="18" width="18" />
                    </div>
                    <p class="t-small">
                        {{ getTrailers(trip) }}
                    </p>
                </div>
            </div>
            <div class="l-stack l-gap-1">
                <div>
                    <div class="timline-bubble">
                        <span v-if="isTripOngoing">?</span>
                    </div>

                    <div
                        v-if="!isTripOngoing"
                        class="l-inline l-center-v l-gap-1"
                    >
                        <CalendarIcon height="18" width="18" />
                        <div>
                            {{ formatDate(trip.last_measurement.timestamp) }}
                        </div>
                        <div>
                            <strong>
                                {{
                                    formatTime(trip.last_measurement.timestamp)
                                }}
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="l-inline l-start l-gap-1">
                    <div v-if="!isTripOngoing">
                        <PinLocationIcon height="18" width="18" />
                    </div>
                    <p>
                        <span class="t-small">{{ trip.end_address }}</span>
                    </p>
                </div>
            </div>
        </div>
    </ListItem>
</template>

<script>
import moment from 'moment-timezone'

import CalendarIcon from '@/components/icons/CalendarIcon'
import ListItem from '@/components/ListItem'
import PersonIcon from '@/components/icons/PersonIcon'
import PinLocationIcon from '@/components/icons/PinLocationIcon'
import TrailerIcon from '@/components/icons/TrailerIcon.vue'

const HIGHLIGHTED_TRACE_COLOR = '#ef2655'

export default {
    components: {
        CalendarIcon,
        ListItem,
        PersonIcon,
        PinLocationIcon,
        TrailerIcon,
    },
    props: {
        trip: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        traceColor() {
            if (this.selected) {
                return HIGHLIGHTED_TRACE_COLOR
            }
            return '#000000'
        },
        isTripOngoing() {
            return this.trip.status === 'running'
        },
    },
    methods: {
        formatDate(time) {
            return moment(time).format('DD.MM.YY')
        },
        formatDistance(distance) {
            return distance >= 1000
                ? `${(distance / 1000).toFixed(2)}km`
                : `${distance}m`
        },
        formatTime(time) {
            return moment(time).format('HH:mm')
        },
        formatTripDuration(startDate, endDate) {
            const timeDiff = moment(endDate).diff(moment(startDate))
            const tripDuration = moment.duration(timeDiff)
            return tripDuration.format('h[h] mm[min] ss[s]', {
                usePlural: false,
            })
        },
        getTrailers(item) {
            return item.trailers
                .filter(trailer => trailer.name !== null)
                .map(item => `${item.name} (${item.percentage}%)`)
                .join(',')
        },
    },
}
</script>

<style lang="scss" scoped>
.timeline {
    position: relative;
    border-left: 2px solid var(--color-trace);
    margin: 0 16px;
    padding: 16px;
}

.timeline-icon {
    position: absolute;
    left: 8px;
}

.timline-bubble {
    --bubble_size: 18px;

    display: grid;
    justify-content: center;
    align-content: center;
    font-weight: bold;
    position: absolute;
    left: calc(((var(--bubble_size) / 2) + 1px) * -1);
    background-color: white;
    border: 2px solid var(--color-trace);
    border-radius: 50%;
    width: var(--bubble_size);
    height: var(--bubble_size);
}
</style>
